<template>
  <div>
    <label class="typo__label">Select Bank</label>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="id"
      label="name"
      :allow-empty="isAllowEmpty"
      @search-change="handleSearch"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const masterRepo = RepositoryFactory.get("master");
import { debounce } from "lodash";

export default {
  props: {
    isAllowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      params: {
        search: "",
      },
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    handleSearch: debounce(async function (keyword) {
      console.log(keyword);
      this.params.search = keyword;
      await this.getData();
    }, 500),
    async customLabel({ code, name }) {
      return `${code} ${name}`;
    },
    async getData() {
      this.$vs.loading();
      try {
        const params = {
          params: this.params,
        };
        const resp = await masterRepo.getBanks(params);
        if (resp.code == 200) {
          this.options = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });

        console.log(error);
      }
      this.$vs.loading.close();
    },
    async setSelected(id, name) {
      this.selected = {
        id: id,
        name: name,
      };
    },
  },
  watch: {
    selected(v) {
      this.$emit("data", v);
    },
  },
};
</script>
