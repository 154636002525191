import Repository from "./repository";

const resource = "api/sfa/v1/mobile-preparation";
const paymentMethod = `${resource}/payment-method`;

export default {
  getPaymentMethodMobile() {
    const params = {
      params: {
        is_mobile: true,
      },
    };
    return Repository.get(`${paymentMethod}`, params);
  },
};
