import Repository from "./repository";

const resource = "/api/v1/pricing-management-v3";

export default {
  getCode(code) {
    return Repository.get(`/api/v1/setting/master-number-code/` + code);
  },
  downloadExcelSku(params) {
    return Repository.get(`${resource}/excel-sku`, params);
  },
  downloadExcelSkuUploadForm(params) {
    return Repository.get(`${resource}/excel-sku-upload-form`, params);
  },
  createSku(params, headers) {
    return Repository.post(`${resource}/create-update-sku`, params, headers);
  },
  createSkuUploadForm(formdata, headers) {
    return Repository.post(
      `${resource}/create-update-sku-upload-form`,
      formdata,
      headers
    );
  },
  getListSalesPrice(params) {
    return Repository.get(`${resource}/list-sales-price`, params);
  },
  getListPriceItemByPriceID(priceID, params) {
    return Repository.get(`${resource}/${priceID}/list-price-item`, params);
  },
  getPriceDetail(id) {
    return Repository.get(`${resource}/` + id);
  },
  changeStatus(id, status) {
    return Repository.post(`${resource}/change-status/` + id, {
      status: status,
    });
  },
};
