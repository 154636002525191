<template>
  <div>
    <label class="typo__label">Select Payment Method</label>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="id"
      :allow-empty="isAllowEmpty"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const mobilePreparationRepo = RepositoryFactory.get("mobilePreparation");

export default {
  props: {
    isAllowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.$vs.loading();
      try {
        const resp = await mobilePreparationRepo.getPaymentMethodMobile();
        if (resp.code == 200) {
          this.options = resp.data;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });

        console.log(error);
      }
      this.$vs.loading.close();
    },
    async setSelected(id, code, name) {
      this.selected = {
        id: id,
        code: code,
        name: name,
      };
    },
  },
  watch: {
    selected(v) {
      this.$emit("data", v);
    },
  },
};
</script>
