import dmsRepository from "./dmsRepository";
import MasterRepository from "./masterRepository";
import PricingManagementV3Repository from "./pricingManagementV3Repository";
import salesReturnV2Repository from "./salesReturnV2Repository";
import mobilePreparationRepository from "./mobilePreparationRepository";

const repositories = {
  master: MasterRepository,
  pricingManagementV3: PricingManagementV3Repository,
  salesReturnV2: salesReturnV2Repository,
  dms: dmsRepository,
  mobilePreparation: mobilePreparationRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
