import Repository from "./repository";

const resourceV2 = "/api/v2/sales-return-management";

export default {
  getCNReturnTypes() {
    return Repository.get(`${resourceV2}/cn-return-types`);
  },
  storeSalesReturn(params) {
    return Repository.post(`${resourceV2}`, params);
  },
  getList(params) {
    return Repository.get(`${resourceV2}`, params);
  },
  getDetail(id) {
    return Repository.get(`${resourceV2}/${id}`);
  },
  doApprovalStatus(id, status, feedback) {
    return Repository.put(`${resourceV2}/${id}/approval-status`, {
      params: {
        status: status,
        feedback: feedback,
      },
    });
  },
};
