<template>
  <vx-card title="Manage Collection Data">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Collection Plan ID</label>
        <vs-input class="w-full" v-model="uuid" type="text" readonly />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Territory</label>
        <vs-input class="w-full" v-model="territory" type="text" readonly />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Collector / Sales</label>
        <vs-input
          class="w-full"
          v-model="collector_sales"
          type="text"
          readonly
        />
      </div>
    </div>

    <!-- table collection plan -->
    <div class="vx-row mb-12">
      <div v-bind:class="[windowPayment ? detailShow : '', detailHide]">
        <div class="core vx-col md:w-1/1 w-full mb-base">
          <vs-table
            search
            stripe
            border
            description
            :sst="false"
            style="padding-bottom: 60px"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
          >
            <template slot="thead">
              <vs-th>Action</vs-th>
              <vs-th>Customer ID / Name</vs-th>
              <vs-th>Total Payment</vs-th>
              <vs-th>Invoice Code</vs-th>
              <vs-th>Invoice Info</vs-th>
              <vs-th>Due Date</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-button
                    size="small"
                    :color="windowPayment ? 'success' : 'primary'"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click="openPayment(tr)"
                  />
                </vs-td>
                <vs-td> {{ tr.customer_code }} - {{ tr.customer_name }}</vs-td>
                <vs-td>{{ tr.total_payment | formatNumber }}</vs-td>
                <vs-td>{{ tr.invoice_no }}</vs-td>
                <vs-td>
                  Number: {{ tr.invoice_no }} <br />
                  Date: {{ tr.invoice_date | formatDate }} <br />
                  Outstanding: {{ tr.outstanding_value | formatNumber }}
                </vs-td>
                <vs-td>{{ tr.due_date | formatDate }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <!-- sidebar payment info -->
      <transition name="detail-fade">
        <div
          v-if="windowPayment"
          v-bind:class="[windowPayment ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <div class="vx-col">
              <h4>Add New Payment</h4>
              <vs-button
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                @click="addNewPayment"
              />
            </div>
            <div class="vx-col w-5/6 my-2">
              <PaymentFormV2
                :data="child"
                v-for="(
                  child, index
                ) in currentOpenPayments.customer_order_payments"
                :key="index"
                :index="index"
                @deletePayment="setDeletePayment"
                @isChangeData="isHasChangeValue = true"
              />
            </div>
          </vs-row>
        </div>
      </transition>
    </div>

    <div class="vx-row gap-2">
      <vs-button
        :disabled="!isHasChangeValue"
        @click="onClickBtnSave()"
        size="small"
        color="primary"
        icon-pack="feather"
        icon="icon-save"
      >
        Send for approval</vs-button
      >

      <vs-button
        @click="() => $router.push('/collection-plan-manage')"
        size="small"
        color="danger"
        icon-pack="feather"
        icon="icon-x"
        >Cancel</vs-button
      >
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import PaymentForm from "./payment-form.vue";
import { formatNumber } from "../../../utils/common";
import PaymentFormV2 from "./payment-form-v2.vue";

export default {
  components: {
    PaymentForm,
    PaymentFormV2,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      windowPayment: false,

      // main data
      uuid: null,
      territory: null,
      collector_sales: null,
      data: null,
      table: this.defaultTable(),
      isCashOrNot: [],
      //   modal edit
      modalEdit: {
        index: null,
        activeModalEdit: false,
        giro_check: null,
        transfer_date: null,
      },

      // option bank
      selectedBank: null,
      optionBank: [],
      // option payment method
      optionPaymentMethod: [],
      collectionPlanLineIds: [],
      isHasChangeValue: false,
      currentOpenPayments: {
        billing_order_id: null,
        customer_order_payments: [],
      },
    };
  },
  computed: {},
  methods: {
    setDeletePayment(index) {
      this.currentOpenPayments.customer_order_payments.splice(index, 1);
    },
    handleClose() {
      this.windowPayment = false;
      // replace data customer order payment from currentOpenPayments to table.data.routes when billing_order_uuid is same
      this.table.data = this.table.data.map((item) => {
        if (
          item.billing_order_uuid == this.currentOpenPayments.billing_order_uuid
        ) {
          item.customer_order_payments =
            this.currentOpenPayments.customer_order_payments;

          // empty currentOpenPayments
          this.currentOpenPayments = {
            billing_order_uuid: null,
            customer_order_payments: [],
          };
        }
        return item;
      });
    },
    openPayment(tr) {
      this.windowPayment = true;
      this.currentOpenPayments = {
        billing_order_uuid: tr.billing_order_uuid,
        customer_order_payments: tr.customer_order_payments,
      };
    },
    addNewPayment() {
      // push new payment from master payment to tr.customer_order_payments
      this.currentOpenPayments.customer_order_payments.push({
        date: "",
        customer_order_payment_id: 0,
        payment_method_id: 0,
        payment_method_name: "",
        payment_value: 0,
        no_payment_reason: "",
        promise_date: "",
        deduction_value: 0,
        deduction_reason: "",
        return_document: false,
        giro_number: "",
        giro_transfer_bank_id: 0,
        giro_transfer_date: "",
        bank_name: "",
        bank_id: 0,
        new_payment_value: null,
        new_deduction_value: null,
        new_deduction_reason: "",
        new_payment_method_id: 0,
        new_payment_method_name: "",
        new_giro_number: "",
        new_giro_transfer_date: "",
        photo_proof_of_payment: "",
        new_bank_id: 0,
        new_bank_name: "",
        notes: "",
        date_transfer: "",
        can_delete: true,
        temp_file: null,
        isNew: true,
        is_payment_changed: true,
        new_bank: null,
      });
    },
    // main method
    async onClickBtnSave() {
      let payload = {
        collection_plan_lines: this.table.data.map((item) => {
          return item.customer_order_payments.map((child) => {
            return {
              ...child,
              collection_plan_line_id: item.collection_plan_line_id,
              customer_order_payment_id: child.customer_order_payment_id,
              deduction_value: parseFloat(child.deduction_value),
              new_payment_value: parseFloat(child.new_payment_value || 0),
              new_deduction_value: parseFloat(child.new_deduction_value),
            };
          });
        }),
      };
      payload.collection_plan_lines = payload.collection_plan_lines.flat();

      this.$vs.loading();

      const id = this.$route.params.id;
      let response = await this.$http.put(
        `/api/sfa/v1/collection-plan/manage/${id}`,
        payload
      );

      if (response.code == 200) {
        this.$vs.notify({
          title: "Success",
          text: response.message,
          color: "success",
        });
        this.$vs.loading.close();
        this.$router.push("/collection-plan-manage");
      } else {
        this.$vs.notify({
          title: "Failed",
          text: response.message,
          color: "danger",
        });
        this.$vs.loading.close();
      }
    },
    defaultTable() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      const id = this.$route.params.id;
      this.$vs.loading();
      let response = await this.$http.get(`/api/sfa/v1/collection-plan/${id}`);

      if (response.code != 200) {
        this.$vs.notify({
          title: "Failed",
          text: response.message,
          color: "danger",
        });

        this.$vs.loading.close();

        return false;
      }

      let data = response.data;
      this.data = data;
      for (let index = 0; index < data.length; index++) {
        this.isCashOrNot.push(false);
      }

      this.uuid = data.uuid;
      this.territory = `${data.territory_code} - ${data.territory_name}`;
      this.collector_sales = `${data.sales_number} - ${data.sales_name}`;
      // add new key in customer_order_payments
      this.table.data = data.routes.map((item) => {
        return {
          ...item,
          customer_order_payments: item.customer_order_payments.map((child) => {
            return {
              ...child,
              temp_file: "",
            };
          }),
        };
      });

      // set collection plan line id
      this.collectionPlanLineIds = data.routes.map((item) => {
        return item.collection_plan_line_id;
      });

      this.$vs.loading.close();
    },
  },
  async mounted() {
    await this.getData();
  },
  filters: {
    formatNumber(val) {
      if (val) {
        return formatNumber(val);
      }
    },
    formatDate(value) {
      if (value == "") {
        return "-";
      } else {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
};
</script>

<style>
.proof-of-payment-upload {
  display: none;
  margin-top: 4px;
}
.file-input-label {
  font-size: 12px;
  padding: 10px 15px;
  border: 1px solid black;
  border-radius: 4%;
}
.border-green {
  border: 1px solid green;
}

.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
