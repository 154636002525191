import Repository from "./repository";

const currency = "/api/v1/master/currency";
const salesChannel = "/api/v1/master/sales-channel";
const distributionChannel = "/api/v1/master/customer-category-all";
const supplier = "/api/v1/master/suppliers";
const pricingGroup = "api/v1/master/pricing-group";
const territory = "api/v1/master/territory";
const sales = "api/v1/master/sales";
const invoice = "/api/v1/invoice/by-code";
const customer = "/api/v1/master/customer";
const reason = "/api/v1/master/reason";
const customerAddress = "/api/v1/master/customer-address";
const warehouseTerritory = "/api/v1/master/warehouse-territory";
const productFestive = "/api/v1/master/productFestives";
const itemUnitV2 = "/api/v2/master/item-unit";
const bank = "/api/sfa/v1/bank";

export default {
  getSalesChannels(params) {
    return Repository.get(`${salesChannel}`, params);
  },
  getCurrencies(params) {
    return Repository.get(`${currency}`, params);
  },
  getDistributionChannels(params) {
    return Repository.get(`${distributionChannel}`, params);
  },
  getSuppliers(params) {
    return Repository.get(`${supplier}`, params);
  },
  getPricingGroups(params) {
    return Repository.get(`${pricingGroup}`, params);
  },
  getTerritories(params) {
    return Repository.get(`${territory}`, params);
  },
  getSales(params) {
    return Repository.get(`${sales}`, params);
  },
  getInvoiceByCode(code) {
    return Repository.get(`${invoice}/${code}`);
  },
  getCustomersByID(ids) {
    const parseIds = ids.join(",");
    return Repository.get(`${customer}/customer-by-ids?ids=${parseIds}`);
  },
  getMasterReason(params) {
    return Repository.get(`${reason}`, params);
  },
  getCustomerAddressShipTo(params) {
    return Repository.get(`${customerAddress}/ship-to`, params);
  },
  getCustomers(params) {
    return Repository.get(`${customer}`, {
      params: params,
    });
  },
  getWarehouseTerritory(params) {
    return Repository.get(`${warehouseTerritory}`, {
      params: params,
    });
  },
  getProductFestiveItemUnits(params) {
    return Repository.get(`${productFestive}/table`, {
      params: params,
    });
  },
  getItemUnitByIDV2(id) {
    return Repository.get(`${itemUnitV2}/${id}`);
  },
  getBanks(params) {
    return Repository.get(`${bank}`, params);
  },
};
